@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@layer base {
    li {
        @apply p-4
    }
    h1 {
        font-size: 30px;
        font-weight: bold;
    }

    p {
        white-space: pre-wrap;
    }
    .gradient {
        background: linear-gradient(90deg, #130574 0%, #549fe5 100%);
        }
    .slide img {
        width:20rem;
        margin: 0 auto;
        }
        
      
        .slide {
          transform: scale(0.2);
          transition: transform 300ms;
          opacity: 0.5;
        }

        @media only screen and (min-width: 600px) {
          .slide {
            transform: scale(0.2);
            transition: transform 300ms;
            opacity: 0.5;
           
            padding-left: 150px;
            padding-right: 150px; 
          }
        }
        
        .activeSlide {
          transform: scale(1.1);
          opacity: 1;
        }
        
        .arrow {
          position: absolute;
          cursor: pointer;
          z-index: 10;
        }
        
        .arrow svg {
          transition: color 300ms;
        }
        
        .arrow svg:hover {
          color: #68edff;
        }
        
        .next {
          right: 0%;
          top: 50%;
        }
        
        .prev {
          left: 0%;
          top: 50%;
        }
    
}

